import React from 'react';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import styled from 'styled-components';
import { FaHeart } from 'react-icons/fa'
import { themeGet } from 'styled-system'

import Box from '../components/Box'

const StyledDD = styled(Dropdown)`
  .Dropdown-control {
    border-radius: 0;
    padding: 1em 52px 1em 10px;
  }
  .Dropdown-control,
  .Dropdown-menu {
    border-color: ${themeGet('colors.primary')};
    text-align: left;
  }

  .Dropdown-option:hover,
  .Dropdown-option.is-selected {
    color: white;
    background-color: ${themeGet('colors.primary')};
  }
`

const StyledSelect = styled(Box)`
  appearance: none;
`;

const heartArrow = (
  <Box position="absolute" right="0.5em" top="50%" transform="translateY(-50%)">
    <FaHeart />
  </Box>
)

export default ({ options, name, onChange, value, placeholder, native, ...props }) => {
  return (
    <Box {...props}>
      {native ? (
        <Box position="relative">
          <StyledSelect
            is="select"
            name={name}
            onChange={onChange}
            value={value}
            borderColor="primary"
            width="100%"
            py="0.5rem"
            px="0.5rem"
            fontSize="16px"
            bg="white"
            borderRadius="0"
          >
            {placeholder && <option value="_">{placeholder}</option>}
            {options.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </StyledSelect>
          {heartArrow}
        </Box>
      ) : (
        <StyledDD
          placeholder={placeholder}
          name={name}
          onChange={(option) => onChange({
            target: {
              value: option.value,
              name,
            },
            persist: () => {},
          })}
          value={options.find((opt) => opt.value === value)}
          options={options}
          arrowOpen={heartArrow}
          arrowClosed={heartArrow}
        />
      )}

    </Box>
  );
};
