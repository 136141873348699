import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi'
import merge from 'lodash/merge'

import Box from './Box'
import theme from './ThemeProvider/theme'

const customStyles = {
  overlay: {
    zIndex: theme.zOrder[3],
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    width: '80%',
    maxWidth: '30em',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    border: 'none',
    padding: '0',
  }
};

Modal.setAppElement('#___gatsby')

export default ({ children, width, noXX, ...props }) => (
  <Modal
    style={merge({}, customStyles, { content: { width } })}
    {...props}
  >
    <Box p="2em">
      {!noXX && (
        <Box position="absolute" top="1em" right="1em" onClick={props.onRequestClose} color="primary">
          <FiX size="2em" />
        </Box>
      )}
      {children}
    </Box>
  </Modal>
)
